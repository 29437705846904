/* profile page css */

.profile-page {
    width: 50%;
    padding: 2% 0 0;
    margin: auto;
}

.form {
    position: relative;
    z-index: 1;
    background: #ffffff;
    max-width: 100%;
    margin: 0 auto 100px;
    padding: 45px;
    /* text-align: center; */
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}



@media screen and (min-width: 300px) and (max-width: 380px) {
    
    .fontcus{
         font-size: 16px !important;
    }
      }
      

.profileimage{
    height: 250px;
    width: 50%;
}

.cus{
    font-weight: 500;
}
/*  
@media screen and (max-width: 1000px) {
    .profileimage{
        height: 169px;
        width: 75%;
    }
    
  } */

  @media screen and (min-width: 300px) and (max-width: 700px) {
    
.profile-page {
    width: 100%;
    padding: 2% 0 0;
    margin: auto;
}
  }
  


  @media screen and (min-width: 300px) and (max-width: 400px) {
    .customs{
        font-size: 29px !important;
    }
    
    
      }


  .detail{
     font-weight: bolder;
     font-size: 24px;
  }
   

  @media screen and (min-width: 300px) and (max-width: 400px) {
    
    .profileimage{
        height: 150px;
        width: 90%;
    }
    
      }

      @media screen and (min-width: 401px) and (max-width: 500px) {
    
        .profileimage{
            height: 185px;
            width: 70%;
        }
        
          }

          
      @media screen and (min-width: 700px) and (max-width: 1000px) {
    
        .profileimage{
            height: 185px;
            width: 80%;
        }
        
          }

          @media screen and (min-width: 1000px) and (max-width: 1300px) {
    
            .profileimage{
                height: 185px;
                width: 65%;
            }
            
              }

.form h1 {
    color: #fff;
    margin-bottom: 30px;
    font-family: math;
    font-weight: bolder;
}

.form input {
    font-family: "Roboto", sans-serif;
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    border-radius: 5px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
}

.form button {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #43A047;
    width: 100%;
    border: 0;
    border-radius: 5px;
    padding: 15px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
}

.form button:hover,
.form button:active,
.form button:focus {
    background: #f9b233;
}

.form .message {
    margin: 15px 0 0;
    color: #b3b3b3;
    font-size: 12px;
}

.form .message a {
    color: #4CAF50;
    text-decoration: none;
}

.form .register-form {
    display: block;
}

/* styling the error (yup) */
.error {
    color: red;
    font-size: 12px;
    /* margin-top: 1px; */
    text-align: left;
    /* Align error messages to the left */
}