/*footer styling*/

.footer-ul {
    margin: 0px;
    padding: 0px;
}

.footer-section {
    background: #151414;
    position: relative;
}



.footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: #7e7e7e;
    line-height: 28px;
}

.footer-social-icon span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
}

.footer-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
}

.footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
}


.footer-content {
    margin-left: 100px;
}

svg {
    margin-right: 5px;
}

.footer-widget-heading h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
}

.footer-widget-heading h3::before {
    content: "";
    position: absolute;
    bottom: -15px;
    height: 2px;
    width: 100px;
    background: #ff5e14;
}

.footer-widget ul li {
    display: inline-block;
    float: left;
    width: 50%;
    margin-bottom: 12px;
}

.footer-widget ul li a {
    color: #878787;
    text-decoration: none;
}

.copyright-area {
    background: #202020;
    padding: 20px;
    padding-top: 30px;
}

.copyright-text p {
    text-align: left;
    font-size: 14px;
    color: #878787;
}

.footer-logo {
    color: white;
    margin-bottom: 5px;
}