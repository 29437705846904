.btn-login {
    width: 100px;
    height: 50px;
    border: none;
    border-radius: 30px;
    background-color: #0B983A;
}

.btn-signup {
    width: 100px;
    height: 50px;
    border: none;
    border-radius: 30px;
    background-color: #F9B233;
}

.btn-login:hover {
    opacity: 50%;
    background-color: #0B983A;
    border: none;
}

.btn-signup:hover {
    opacity: 50%;
    background-color: #F9B233;
    border: none;
}